




import Vue from "vue"
import VideoLayout from "./VideoLayout.vue"
import { TwitchEmbed, TwitchEmbedLayout } from "twitch-player"
import { v4 } from "uuid"

export default Vue.extend({
  name: "TwitchPlayer",
  components: {
    VideoLayout
  },
  props: {
    muted: {
      type: Boolean,
      default: false
    },
    streamUrl: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      player: null,
      divId: `twitch-player-${v4()}`
    }
  },
  computed: {
    twitchUrl() {
      return "https://player.twitch.tv/"
    },
    twitchChannel() {
      return this.streamUrl
        ? String(this.streamUrl).substr(
            String(this.streamUrl).lastIndexOf("/") + 1
          )
        : null
    },
    url() {
      return `${this.twitchUrl}?channel=${this.twitchChannel}&parent=${window.location.hostname}&autoplay=true&muted=${this.muted}`
    }
  },
  mounted() {
    this.player = new TwitchEmbed(this.divId, {
      width: "100%",
      height: "100%",
      channel: this.twitchChannel,
      layout: TwitchEmbedLayout.VIDEO
    })

    this.player.addEventListener("ready", () => {
      if (this.player.getMuted()) this.player.setMuted(false)
    })
  },
  methods: {}
})
